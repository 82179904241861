var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Подтверждение номера",
        "close-on-click-modal": false,
        visible: _vm.visible,
        width: "526px",
        "custom-class": "home-modal",
      },
      on: { close: _vm.beforeClose },
    },
    [
      _c("div", { staticClass: "modal-container" }, [
        _c(
          "div",
          { staticClass: "smsConfirmation" },
          [
            _c(
              "h3",
              { staticClass: "smsConfirmation__content-title h3-modal" },
              [
                _vm._v(" Для завершения регистрации "),
                _c("br"),
                _vm._v(" необходимо подтвердить ваш номер "),
              ]
            ),
            _c(
              "div",
              { staticClass: "smsConfirmation__content-dispatchNumber" },
              [
                _c("p", { staticClass: "dispatchNumber-text p-modal" }, [
                  _vm._v("Мы отправили код на номер"),
                ]),
                _c("span", { staticClass: "dispatchNumber-number" }, [
                  _vm._v(" " + _vm._s(_vm.phoneRegex(_vm.phone)) + " "),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "smsConfirmation__content-linkWrapper" },
              [
                _c(
                  "el-link",
                  {
                    staticClass: "smsConfirmation-link",
                    attrs: { type: "primary", underline: false },
                    on: { click: _vm.backToNumber },
                  },
                  [_vm._v(" Изменить номер ")]
                ),
              ],
              1
            ),
            !_vm.isProd
              ? _c("div", { staticClass: "testTitle" }, [
                  _vm._v(" Код для подтверждения учетной записи: "),
                  _c("span", { staticClass: "smsCode" }, [
                    _vm._v(_vm._s(_vm.code)),
                  ]),
                ])
              : _vm._e(),
            _c(
              "el-form",
              {
                ref: "sms_form",
                staticClass: "sms_form",
                attrs: {
                  model: _vm.sms_form,
                  rules: _vm.rules,
                  "validate-on-rule-change": false,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "sms_form-input" },
                  [
                    _c("IqInputForm", {
                      ref: "sms_form.code",
                      attrs: {
                        ownclass: "no-zoom",
                        "input-width": "100%",
                        placeholder: "Код подтверждения",
                        prop: "code",
                        rules: _vm.rules.code,
                      },
                      on: { "on-focus": _vm.clearValidate },
                      model: {
                        value: _vm.sms_form.code,
                        callback: function ($$v) {
                          _vm.$set(_vm.sms_form, "code", $$v)
                        },
                        expression: "sms_form.code",
                      },
                    }),
                    _vm.codeMessage.length
                      ? _c("div", { staticClass: "customErrors" }, [
                          _c("div", { staticClass: "customErrors__text" }, [
                            _vm._v(_vm._s(_vm.codeMessage)),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "dispatchButton" },
                  [
                    _c(
                      "iq-button",
                      {
                        class: { fullWidthMedium: _vm.isMobile },
                        attrs: {
                          color: "primary",
                          disabled: _vm.isButtonDisabled,
                        },
                        on: {
                          onClick: function ($event) {
                            return _vm.dispatchSms("sms_form")
                          },
                        },
                      },
                      [_vm._v(" Проверить код ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "smsConfirmation-resendingText" },
              [
                _c("TimerButton", {
                  attrs: { "timer-interval": _vm.timerInterval },
                  on: { "button-clicked": _vm.reDispatchCode },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }